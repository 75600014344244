/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    array, bool, string, shape, object,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import  DesktopFooterBlock from './DesktopFooterBlock/DesktopFooterBlock';
import ShopTheSiteBanner from '../../../pages/Account/components/GiftList/ShopTheSiteBanner';
import LegalLinks from './LegalLinks';
import LegalRecaptchaLinks from './LegalRecaptchaLinks';

const useStyles = makeStyles((theme) => ({
    desktopFooter: {
        backgroundColor: theme.palette.cms?.desktopBgFooter || theme.palette.desktopBgFooter,
        position: 'relative',
        paddingBottom: 48,
    },
}));
const DesktopGraphqlFooter = ({
    brand, desktopFooterData, self, titles, close, legalData, isRecaptchaCheckoutEnabled,
}) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [title, setTitles] = useState('');

    const setTitle = () => {
        if (self) {
            setTitles(titles);
            setOpenDialog(true);
        } else {
            setTitles(title);
            setOpenDialog(true);
        }
    };
    const setDialog = () => {
        if (self) {
            setOpenDialog(close);
        } else {
            setOpenDialog(false);
        }
    };

    const  desktopFooter = () => {
        if (desktopFooterData?.[0]?.footer_blocks?.length) {
            const footerBlocks = desktopFooterData[0].footer_blocks;
            const bottomLinks = footerBlocks.filter((item) => item.footer_bottom_links);
            const popupData = bottomLinks.map((x) => x.footer_bottom_links.link_group.filter((y) => y.popup || (y.link.href.indexOf('About-Us-Terms-of-Use') === 1 || y.link.href.indexOf('About-Us-Privacy-Policy') === 1)));
            const filtered = popupData.filter((el) => el.length > 0);
            const checkoutPage  = (typeof window === 'object') ? (/checkout/.test(window.location.pathname)) : false;
            const addonsPage  = (typeof window === 'object') ? (/add-ons/.test(window.location.pathname)) : false;
            return (
                <div className={classes.desktopFooter}>
                    {footerBlocks.map((block, index) => {
                        const keyItem = `desktopFooterBlock${index}`;
                        let setBlock = {};
                        if (checkoutPage || addonsPage) {
                            if (Object.keys(block)[0] === 'copyright') {
                                setBlock = block;
                            }
                        } else {
                            setBlock = block;
                        }

                        return (
                            <DesktopFooterBlock
                                block={setBlock}
                                self={this}
                                setDialog={setDialog}
                                setPopupTitle={setTitle}
                                key={keyItem}
                                popUpData={filtered[0]}
                                openDialog={openDialog}
                                title={title}
                            />
                        );
                    })}
                    <LegalLinks data={legalData} />
                    <LegalRecaptchaLinks isRecaptchaCheckoutEnabled={isRecaptchaCheckoutEnabled} legalData={legalData} brand={brand} />
                    <ShopTheSiteBanner />
                </div>
            );
        }
        return (<></>);
    };

    return desktopFooter();
};
DesktopGraphqlFooter.defaultProps = {
    desktopFooterData: [],
};

DesktopGraphqlFooter.propTypes = {
    brand: object.isRequired,
    desktopFooterData: shape({
        footer_blocks: array.isRequired,
        locale: string.isRequired,
        title: string.isRequired,
        uid: string.isRequired,
    }).isRequired,
    legalData: object.isRequired,
    isRecaptchaCheckoutEnabled: bool.isRequired,
};

export default DesktopGraphqlFooter;
